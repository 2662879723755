.navbar-brand {
  font-size: 24px;
  font-weight: bold;
}

#basic-navbar-nav.show {
  display: flex;
  margin: 0 1.5rem 0.75rem;
  flex-direction: column;
  gap: 1rem;
}

.dropdown-menu[data-bs-popper] {
  left: unset;
  right: 0;
  padding: 8px 5px;
}

.collapse.show {
  #profile {
    margin-right: 0 !important;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
  }
}

.selected-nav {
  color: blue !important;
  font-weight: bold;
}

#header-dropdown {
  color: #1f2937;
  cursor: pointer;
}

#header-dropdown::after {
  display: none;
}
