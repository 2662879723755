.bg-gray {
  background-color: #f7f8fa;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.locations-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  margin: auto 10%;
  border: 1px solid #e5e7eb;
}

thead > tr > th {
  padding: 1.5rem 0.5rem !important;
}

tr {
  border: 1.5px solid #e5e7eb;
}

#dropdown-basic::after {
  display: none;
}

.table-dropdown-menu {
  min-width: fit-content;
  padding: inherit 5px;
  a {
    text-align: center;
    margin: 0;
    border-bottom: 1px solid #e5e7eb;
  }
  :hover {
    color: #2563eb;
    background-color: transparent;
  }
  :last-child {
    border: 0;
  }
}

#dropdown-basic {
  color: #68737d;
  cursor: pointer;
}

.page-link {
  color: #374151;
}

.page-item * {
  border-radius: 5px;
}

.page-link[role="button"] {
  padding: 0.25rem 0.5rem;
}
