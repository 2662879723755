.blue-line {
  border: 0;
  height: 3px;
  background: #3758f9 !important;
  width: 15%;
  margin: 0 auto;
  opacity: 1;
}

.containerStyle {
  width: 60%;
  margin: 0 auto;
}

.containerFluidStyle {
  max-width: 860px;
}

input[type="text"]:disabled {
  color: #3758f9;
  font-weight: bold;
}

.datesContainer {
  width: -webkit-fill-available;
}

input[type="radio"] {
  margin-right: 5px;
}

.radio-button-container {
  margin-right: 10px;
}

.radio-button-group {
  display: flex;
  flex-direction: row;
}

strong {
  color: #2563eb;
  font-size: x-large;
}
