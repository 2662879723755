.icon-no-background {
  background-color: transparent;
  border: none;
}

.select-with-icon {
  position: relative;
}

.select-with-icon .icon-no-background {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  z-index: 1;
}

.select-with-padding {
  padding-left: 50px;  
}

.select-border-radius {
  border-radius: 0.25rem 0 0 0.25rem !important;
}
.modal-90w {
  max-width: 50% !important;
}

.custom-modal-padding {
  padding: 6rem !important;
}

.blue-line {
  border: 0;
  height: 3px;
  background: #3758F9 !important; 
  width: 15%; 
  margin: 0 auto; 
  opacity: 1;
}

input {
  color: black !important;
  font-weight: normal !important;
}