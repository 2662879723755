.blue-line {
  border: 0;
  height: 3px;
  background: #3758f9 !important;
  width: 15%;
  margin: 0 auto;
  opacity: 1;
}

.containerStyle {
  width: 60%;
  margin: 0 auto;
}

.containerFluidStyle {
  max-width: 860px;
}

input[type="text"]:disabled {
  color: #3758f9;
  font-weight: bold;
}

.passwords-div {
  border: 1px dotted lightgray;
  border-radius: 5px;
  padding: 5px;
}

.passwords-div-label {
  color: gray;
  font-size: small;
  font-style: italic;
}
