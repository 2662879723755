
.circle{
  left:50%;
  top:50%;
  position: fixed;
  margin-left:-40px; 
  margin-top:-40px; 
  height: 4rem;
  width: 4rem;
}
