.map {
  height: 100vh;
  width: 100vw;
}

body {
  margin: 0px;
  padding: 0px;
}

.ol-control {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: 2px;
}

.ol-full-screen {
  top: 0.5em;
  right: 0.5em;
}

.popup {
  position: absolute;
  display: none;
  background-color: white;
  padding: 5px;
  border: 1px solid black;
  z-index: 999;
}

.tab-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-buttons label {
  background: none;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  color: #555;
  display: inline-block;
  background-color: #f9fafb;
  border-bottom: 2px solid #e0e0e0;
}

input[type="radio"] {
  display: none;
}

#info:checked ~ .content .info-content,
#loja:checked ~ .content .loja-content {
  display: block;
  margin-bottom: 20px;
}

.content > div {
  display: none;
}

.tab-buttons label[for="info"]:before {
  content: "";
  display: block;
  border-bottom: 2px solid transparent;
}

.tab-buttons label[for="loja"]:before {
  content: "";
  display: block;
  border-bottom: 2px solid transparent;
}

#info:checked ~ .tab-buttons > label[for="info"],
#loja:checked ~ .tab-buttons > label[for="loja"] {
  color: #1f44f8;
  background-color: #ebefff;
  border-bottom: 2px solid #1f44f8;
}

#loja:checked ~ div > #save-button::after {
  content: "SELECIONAR LOJA";
}

#info:checked ~ div > #save-button::after {
  content: "SALVAR PONTO";
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.form-group label {
  margin-bottom: 5px;
  font-size: 14px;
}

.form-group input {
  padding: 7px 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}
