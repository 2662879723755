.blue-line {
  border: 0;
  height: 3px;
  background: #3758f9 !important;
  width: 15%;
  margin: 0 auto;
  opacity: 1;
}

.containerStyle {
  width: 60%;
  margin: 0 auto;
}

.containerFluidStyle {
  max-width: 860px;
}

input[type="text"]:disabled {
  color: #3758f9;
  font-weight: bold;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 10px;
}

.uploadButton {
  background-color: white;
  color: #0d6efd;
  border: 2px solid #0d6efd;
  padding: 5px 10px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
}

label {
  font-weight: bold;
}

span {
  font-weight: bold;
}

.image-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.image-appearances {
  width: 100%;
  height: 250px;
}

.edit-store-style {
  width: 60%;
  margin: 0 auto;
}

.edit-store-container {
  max-width: 860px;
  padding-top: 10px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}




