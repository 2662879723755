.bg-gray {
  background-color: #f7f8fa;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 8%;
  padding-bottom: 8%;
}

.ads-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 80%;
  margin: auto 10%;
  border: 1px solid #e5e7eb;
  border-radius: 5px;
}

thead > tr > th {
  padding: 1.5rem 0.5rem !important;
}

tr {
  border: 1.5px solid #e5e7eb;
}

#dropdown-basic::after {
  display: none;
}

.table-dropdown-menu {
  min-width: fit-content;
  padding: 8px 5px;
  a {
    text-align: center;
    margin: 0;
    border-bottom: 1px solid #e5e7eb;
  }
  :hover {
    color: #2563eb;
    background-color: transparent;
  }
  :last-child {
    border: 0;
  }
}

#dropdown-basic {
  color: #68737d;
  cursor: pointer;
}

.page-link {
  color: #374151;
}

.page-item * {
  border-radius: 5px;
}

.page-link[role="button"] {
  padding: 0.25rem 0.5rem;
}

.location-nav {
  text-align: center;

  * {
    margin: 0;
    align-items: center;
    justify-content: center;
  }

  p {
    font-size: 0.75rem !important;
  }

  a {
    color: #374151;
    :hover {
      color: #2563eb;
    }
  }
}

.modal-content {
  align-items: center;
}

.modal-header {
  border: none;
  flex-direction: column;
  padding-bottom: 15px;
}

.modal-footer {
  border: none;
  padding: 35px 66px 16px 66px;
  width: -webkit-fill-available;
  justify-content: center;
  gap: 10px;
  button {
    height: 50px;
    width: 190px;
  }
}

.btn-light {
  border: 1px solid #dfe4ea;
  background-color: white;
  font-weight: bold;
}

.btn-danger {
  background-color: #e10e0e;
  font-weight: bold;
}

.modal-body {
  padding: 0;
}

.modal-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e10e0e;
  background-color: #feebeb;
  border-color: #feebeb !important;
  padding: 1rem;
  margin: 22px 0;
}
