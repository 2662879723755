.bg-gray {
  background-color: #f7f8fa;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.login-form-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin: auto;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: white;
}

.login-body {
  width: 70%;
}

.login-footer {
  margin: 20px 0;
  text-align: center;
  font-size: 14px;
}

.login-header {
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 31px;
  }
  margin: 30px 0 22px 0;
}

.button-send {
  border-radius: 5px;
  width: 100%;
  background: rgba(55, 88, 249, 0.9);
  border: 1px solid #3758f9;
}

.button-send:hover {
  background: rgba(55, 88, 249, 0.7);
  border: 1px solid #3758f9;
  border-radius: 10px;
}

.button-cancel {
  border-radius: 10px;
  width: 100%;
  background: #ccc;
  border: 1px solid #ccc;
  margin-top: 10px;
}
